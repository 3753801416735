'use client'

import Link from "next/link"
import Image from "next/image"
import { ChevronRightIcon } from "@radix-ui/react-icons"

import { getAssetUrl, ICONS, SPECIFICITY, TYPE } from "@/lib/assets"
import { WithLayoutBorders } from "@/components/layout/with-layout-borders"
import { cn } from "@/lib/utils"
import {
  ACCOUNT_TAKEOVER_ROUTE, BONUS_ABUSE_ROUTE, CREDIT_UNDERWRITING_ROUTE, IDENTITY_FRAUD_ROUTE,
  ONBOARDING_ROUTE, USER_PROSPECTING_ROUTE
} from "@/app/routes"

const SOLUTIONS = [
  {
    heading: "Onboarding",
    description: "Accelerate onboarding with digital footprint analysis ensuring only verified users access the platform.",
    image: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.onboardingFilled),
    href: ONBOARDING_ROUTE
  },
  {
    heading: "Bonus Abuse",
    description: "Safeguard your campaigns with behavior analytics that detect and prevent bonus abuse.",
    image: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.bonusFilled),
    href: BONUS_ABUSE_ROUTE
  },
  {
    heading: "Account Takeover",
    description: "Protect user accounts with real-time measures that detect and block takeover attempts.",
    image: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.takeoverFilled),
    href: ACCOUNT_TAKEOVER_ROUTE
  },
  {
    heading: "User Prospecting",
    description: "Discover high-value prospects and reduce risk using tailored, data-driven insights.",
    image: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.usrProspectFilled),
    href: USER_PROSPECTING_ROUTE
  },
  {
    heading: "Identity Fraud",
    description: "Identify and counter identity fraud at all stages with advanced verification and monitoring.",
    image: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.identityFilled),
    href: IDENTITY_FRAUD_ROUTE
  },
  {
    heading: "Credit Underwriting",
    description: "Enhance credit underwriting with risk assessments that filter out fraudulent applications.",
    image: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.underwritingFilled),
    href: CREDIT_UNDERWRITING_ROUTE
  }
]

const SolutionItem = ({
  item
}) => {
  const { heading, description, image, href } = item;
  return (
    <Link
      className={cn(
        "group box-border w-full flex justify-start items-start p-4 gap-x-4 border border-layoutBorder bg-gray50",
        "rounded-lg md:rounded-2xl md:w-[calc((100%-16px)/2)] md:flex-col md:p-8 md:gap-y-4 md:border-r",
        "xl:w-[calc((100%-64px)/3)] xl:gap-y-6"
      )}
      href={href}
    >
      <div
        className={cn(
          "w-8 h-8 relative shrink-0 rounded-sm mt-2 md:mt-0",
          "md:w-12 md:h-12"
        )}
      >
        <Image
           alt={`${heading} solution icon`}
           src={image}
           fill
        />
      </div>
      <div
        className={cn(
          "flex-grow flex flex-col justify-start items-start",
          "md:w-full md:gap-y-4 xl:gap-y-6"
        )}
      >
        <div
          className={cn(
            "w-full flex flex-wrap justify-between items-center",
            "md:justify-start gap-x-2"
          )}
        >
          <h3
            className={cn(
              "w-full text-start text-txtP text-xl leading-150 font-medium",
              "md:text-[calc(7px+1.4998vw)] md:leading-[133%]",
              "xl:text-txl"
            )}
          >{heading}</h3>
          <p
            className={cn(
              "!hidden w-full !text-txtS text-txsm leading-150 line-clamp-3",
              "md:!block md:text-[calc(4px+1.0875vw)] ",
              "xl:text-tbase"
            )}
          >{description}</p>
        </div>
        <div
          className={cn(
            "flex gap-x-1 py-2 items-center justify-start"
          )}
        >
          <span
            className={cn(
              "text-sm text-txtAction font-medium leading-115 group-hover:text-actnBrdr",
              "md:text-base leading-125"
            )}
          >Learn More</span>
          <ChevronRightIcon
            className={cn(
              "text-txtAction w-3 h-3 md:w-4 md:h-4",
              "group-hover:text-actnBrdr",
            )}
          />
        </div>
      </div>
    </Link>
  )
}

const LandingSolutions = () => {

  return (
    <section
      className={cn(
        "w-full flex flex-col justify-start items-center gap-y-10",
        "md:gap-y-6", "xl:gap-y-12"
      )}
    >
      <h2
        className={cn(
          "w-full text-[30px] text-txtP font-medium leading-[38px] text-left",
          "md:text-[calc(16px+2.7875vw)] md:w-[60%] md:leading-120 md:self-start md:-tracking-[0.72px]",
          "xl:w-full xl:text-t3xl xl:leading-125 xl:-tracking-[0.96px]"
        )}
      >
        Strategic solutions for 
        business excellence
      </h2>
      <div
        className={cn(
          "w-full flex flex-wrap justify-start items-start gap-y-4 gap-x-4",
          "xl:gap-8"
        )}
      >
        {SOLUTIONS.map((item, index) => (<SolutionItem key={index} item={item} />))}
      </div>
    </section>
  )
}

export default WithLayoutBorders(LandingSolutions)
